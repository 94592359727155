main.app {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position);
  --tw-gradient-to: #e5e5e5 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
}

main.app .splash {
  flex-direction: column;
  gap: .75rem;
  height: 12rem;
  display: flex;
}

main.app .splash svg {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

main.app .splash .title {
  flex-direction: column;
  display: flex;
}

main.app .splash .title h1 {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.75rem;
}

main.app .splash .title small {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .75rem;
  font-weight: 300;
  line-height: 1rem;
}
/*# sourceMappingURL=index.85b41179.css.map */
