main.app {
  @apply flex flex-1 bg-gradient-to-b from-neutral-50 to-neutral-200 w-full h-full p-12;
  @apply justify-center items-center;

  .splash {
    @apply flex flex-col h-48 gap-3;
    svg {
      @apply text-cyan-700;
    }

    .title {
      @apply flex flex-col;

      h1 {
        @apply font-extralight text-xl uppercase tracking-widest;
      }

      small {
        @apply text-xs font-light font-mono text-gray-400;
      }
    }
  }
}
